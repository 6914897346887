@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-sans;
}

nav ul {
  @apply flex space-x-4;
}

nav ul li a {
  @apply text-white no-underline hover:underline;
}

footer {
  @apply bg-gray-800 text-white text-center p-4 fixed bottom-0 w-full;
}
